<template>
  <div class="">
  <div ref="statsContainer" class="row stats-container" style="margin: 0 auto;">
    <div class="col-10 col-md-4 stat p-4 bg-gradient-primary text-white mt-3 mt-md-0">
      <div style="display: inline-block;padding: 10px;width: 100%;">
        <i class="fa fa-money-bill text-white  float-start" style="font-size: 60px"></i>
        <h1 class="stat-number text-white float-end">{{ animatedOpenRequests }}</h1>
      </div>
      <h3 class="text-start fw-bold">{{ $t("Available Shifts") }}</h3>
      <p class="text-start m-0">{{ $t("Current shifts available for nurses to claim.") }}</p>
    </div>
    <div class="col-10 col-md-4 stat p-4 bg-gradient-primary text-white mt-3 mt-md-0">
      <div style="display: inline-block;padding: 10px;width: 100%;">
        <i class="fa fa-user-nurse text-white float-start" style="font-size: 60px"></i>
        <h1 class="stat-number text-white float-end">{{ animatedRegisteredUsers }}</h1>
      </div>
      <h3 class="text-start fw-bold">{{ $t("Registered Nurses") }}</h3>
      <p class="text-start m-0">{{ $t("Qualified nurses ready to take on any open shifts.") }}</p>
    </div>
  </div>
  </div>
</template>

<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"

export default {
  // props: {
  //   openRequests: {
  //     type: Number,
  //     required: true,
  //   },
  //   registeredUsers: {
  //     type: Number,
  //     required: true,
  //   },
  // },
  data() {
    return {
      animatedOpenRequests: 0,
      animatedRegisteredUsers: 0,
      hasAnimated: false, // To check if animation has already occurred
    }
  },
  async mounted() {
    await this.fetchCounts()
    this.createObserver()

  },
  methods: {
    async fetchCounts() {
      //  request-getEntityCounts
      this.registeredUsers = 267 //await this.$store.dispatch("countDocs", {path: "employees"})
      const res = await FirebaseFunctionsHelper.callFunction("request-getEntityCounts", {})
      this.openRequests = res.length > 0 ? res[0].requests : 0
          // await this.$store.dispatch("countCollectionGroupDocs", {path: "requests", statuses: ["VERIFIED"], sorting: {sortBy: "createdAt", sortDirection: "desc"}})
    },
    animateCount(prop, target) {
      const duration = 2000 // duration of the animation in ms
      const start = 0
      const increment = Math.ceil(target / (duration / 100)) // Calculate how much to increment every 100ms

      const timer = setInterval(() => {
        this[prop] += increment // Increment the current value

        // Check if we've reached or exceeded the target
        if (this[prop] >= target) {
          this[prop] = target // Set it to the target value
          clearInterval(timer) // Stop the interval
        }
      }, 100) // Update every 100ms
    },
    createObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.hasAnimated) {
            this.hasAnimated = true // Mark as animated
            this.animateCount("animatedOpenRequests", this.openRequests)
            this.animateCount("animatedRegisteredUsers", this.registeredUsers)
            observer.disconnect() // Stop observing after animation starts
          }
        })
      })

      observer.observe(this.$refs.statsContainer) // Observe the stats container
    },
  },
}
</script>

<style scoped>
.stats-container {
  display: flex;
  justify-content: space-around;
  font-family: Arial, sans-serif;
}

.stat {
  text-align: center;
}

.stat-number {
  font-size: 3em;
  animation: fade-in 0.5s ease-in-out;
  font-family: 'FontAwesome',serif;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.stats-container .stat.p-4:hover {
  transform: scale(1.2);
}

.stats-container .stat.p-4 {
  transition: all 0.5s ease-out;
  border-radius: 20px;
}
</style>
